























































































import { Component, Mixins } from "vue-property-decorator";
import { validationMixin } from "vuelidate";
import XForm from "@/components/SimpleForm.vue";
import ButtonWithMessage from "./ButtonWithMessage.vue";
import { signUpForm } from "@/assets/scripts/form-fields/main";
import AppApiMixin from "../mixins/AppApi.vue";
import VueRecaptcha from "vue-recaptcha";

const { values, attrs, validations, errors } = signUpForm;

@Component({
  components: {
    XForm,
    ButtonWithMessage,
    VueRecaptcha
  },
  mixins: [validationMixin],
  computed: errors,
  validations,
})
class SignUpForm extends Mixins(AppApiMixin, XForm) {
  [x: string]: any;

  values = values;
  allFieldAttrs = attrs;

  useSubmitButtonLoading = false;
  submitError = "";
  submitSuccess = "";

  sitekey = process.env && process.env.VUE_APP_RECAPTCHA_SITE_KEY || "";

  public onCaptchaExpired() {
    this.$refs.recaptcha.reset();
  }

  public validate() {
    this.$refs.recaptcha.execute();
  }

  submitForm(recaptchaToken: string) {
    this.submitError = "";
    this.submitSuccess = "";
    this.useSubmitButtonLoading = true;

    const {
      taxpayerId: inn,
      company: org_name,
      email,
      phone: tel,
      contractList: text,
    } = this.getValues();

    this.signUp({ inn, org_name, email, tel, text, recaptchaToken })
      .then((_) => {
        this.submitSuccess = "Заявка успешно отправлена";
      })
      .catch((e) => {
        this.submitError = e;
      })
      .finally(() => {
        this.useSubmitButtonLoading = false;
      });
  }
}

export default SignUpForm;
